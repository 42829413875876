import React from 'react'
import './HomePage.css'
import NavBar from '../../components/NavBar/NavBar'
import Hero from '../../components/Hero/Hero'
import VideoSection from '../../components/Video/VideoSection'
import StatsSection from '../../components/Stats/StatsSection'
import BlogSection from '../../components/BlogSection/BlogSection'
import Footer from '../../components/Footer/Footer'
import ContactUs from '../../components/Contact/ContactUs'
import TopicSection from '../../components/TopicSection/TopicSection'
import About from '../../components/About/About'
// import InstructorSection from '../../components/InstructorSection/InstructorSection'
import BrowseCourse from '../../components/Browse/BrowseCourse'


function HomePage() {
  return (
    <div className="homepage-container">
      <NavBar />
      <Hero />
      <TopicSection />
      <About />
      <BrowseCourse />
      <VideoSection />
      <StatsSection />
      <BlogSection />
      <ContactUs />
      <Footer />
    </div>
  )
}

export default HomePage