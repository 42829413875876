import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Import BrowserRouter and Route
import CoursePage from './pages/CoursePage/CoursePage';
import HomePage from './pages/HomePage/HomePage';
import Careers from './pages/Careers/Careers';
import BlogPage from './pages/BlogPage/BlogPage';


function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path='/' element={<HomePage />} /> {/* Define route for HomePage */}
          <Route path='/courses' element={ <CoursePage />} /> {/* Define route for CoursePage */}
          <Route path='/careers' element={ <Careers />} /> {/* Define route for CareersPage */}
          <Route path='/blog' element = {<BlogPage />} /> {/*Define Blog/SuccessStory page*/}
          {/* Add more routes for other pages as needed */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
