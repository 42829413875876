import React from 'react'
import './Hero.css'
import banner5 from '../../assets/images/hero-banner-5.jpg'
import banner6 from '../../assets/images/hero-banner-6.jpg'
import heroshape1 from '../../assets/images/hero-shape-1.svg'
import heroshape2 from '../../assets/images/hero-shape-2.png'
import { IonIcon } from '@ionic/react';
import { arrowForwardOutline } from 'ionicons/icons';

function Hero() {
    return (
        <div className="hero-section section has-bg-image">
            <div className="container">
                <div className="hero-content">

                    <h1 className="h1 section-title">
                        The Best Program to <span className="span">Enroll</span> for Exchange
                    </h1>

                    <p className="hero-text">
                        Excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit.
                    </p>
                    <div className="button-container">
                        <a href="#" className="btn has-before">
                            <span className="span">Take a Test</span>

                            <IonIcon icon={arrowForwardOutline} aria-hidden="true" />
                        </a>
                        <a href="#" className="btn-2 has-before">
                            <span className="span">Book a Demo</span>

                            <IonIcon icon={arrowForwardOutline} aria-hidden="true" />
                        </a>
                    </div>

                </div>
                <figure className="hero-banner">

                    <div className="img-holder one" style={{ '--width': 270, '--height': 300 }}>
                        <img src={banner6} width="270" height="300" alt="hero banner" className="img-cover" loading='lazy' />
                    </div>

                    <div className="img-holder two" style={{ '--width': 240, '--height': 370 }}>
                        <img src={banner5} width="240" height="370" alt="hero banner" className="img-cover" loading='lazy'/>
                    </div>

                    <img src={heroshape1} width="380" height="190" alt="" className="shape hero-shape-1" loading='lazy' />

                    <img src={heroshape2} width="622" height="551" alt="" className="shape hero-shape-2" loading='lazy' />

                </figure>

            </div>
        </div>
    )
}

export default Hero