import React, { useState, useEffect } from 'react'
import './NavBar.css';
import { IonIcon } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import { menuOutline } from 'ionicons/icons';
import logo from '../../assets/images/logo.svg'
import { Link } from 'react-router-dom';
function NavBar() {
    const [isActive, setIsActive] = useState(false);
    const [isFixed,setisFixed] = useState(false);
   
    
    useEffect(() => {
        const handleScroll = () => {

            const header = document.querySelector(".header");
            if (header && window.scrollY > 100) {
                setisFixed(true);
            } else {
                setisFixed(false);
            }
        };
        
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleNavbar = () => {
        setIsActive(!isActive);
    };

    const closeNavbar = () => {
        setIsActive(false);
    };

    return (
        <header className="header" data-header>
            <div className="container">
                <a href="#" className="logo">
                    <img src={logo} width="130" height="10" alt="EduWeb logo" />
                </a>
                <nav className={`navbar ${isActive ? "active" : ""}`} data-navbar>

                    <div className="wrapper">
                        <a href="#" className="logo">
                            <img src={logo} width="162" height="50" alt="EduWeb logo" />
                        </a>
                        
                        <button className="nav-close-btn" aria-label="close menu"  onClick={toggleNavbar} data-nav-toggler>
                            <IonIcon icon={closeOutline} />
                        </button>
                    </div>

                    <ul className="navbar-list">

                        <li className="navbar-item">
                            <Link className="navbar-link"  onClick={closeNavbar} data-nav-link to="/">Home</Link>

                            {/* <a href="#home" className="navbar-link" onClick={closeNavbar} data-nav-link>Home</a> */}
                        </li>

                        <li className="navbar-item">
                            <a href="#about" className="navbar-link" onClick={closeNavbar} data-nav-link>About</a>
                        </li>

                        <li className="navbar-item">
                            <a href="#about" className="navbar-link" onClick={closeNavbar} data-nav-link to = "/careers">Careers</a>
                        </li>

                        <li className="navbar-item">
                            <Link className="navbar-link"  onClick={closeNavbar} data-nav-link to="/courses">Courses</Link>
                            {/* <a href="#courses" className="navbar-link" onClick={closeNavbar} data-nav-link>Courses</a> */}
                        </li>

                        <li className="navbar-item">
                            {/* <a href="#blog" className="navbar-link" onClick={closeNavbar} data-nav-link>Blog</a> */}
                            <Link className="navbar-link"  onClick={closeNavbar} data-nav-link to="/blog">Blog</Link>
                        </li>

                        <li className="navbar-item">
                            <a href="#contact" className="navbar-link" onClick={closeNavbar} data-nav-link>Contact</a>
                        </li>
                        <li className="navbar-item nav-login">
                            <a href="#" className="navbar-link" onClick={closeNavbar} data-nav-link>Login/Signup</a>
                        </li>
                    </ul>

                </nav>
                <div className="header-actions">
                    <button className='btn-auth login'>
                        Login
                    </button>
                    <button className='btn-auth signup'>
                        Signup
                    </button>
                    <button className="header-action-btn" aria-label="open menu"  onClick={toggleNavbar} data-nav-toggler>
                       
                        <IonIcon icon={menuOutline} aria-hidden="true" />
                    </button>

                </div>
                <div className={`overlay ${isActive ? "active" : ""}`}  onClick={closeNavbar} data-nav-toggler data-overlay></div>
            </div>
        </header>
    )
}

export default NavBar