import React, { useState, useEffect } from 'react';
import './CoursePageBanner.css';

const CoursePageBanner = () => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="coursepagebanner" style={{ backgroundPositionY: -scrollY * 0.5 }}>
    
    </div>
  );
};

export default CoursePageBanner;
