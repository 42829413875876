import React from 'react'
import './About.css'
import aboutBanner from '../../assets/images/about-banner.jpg'
import aboutShape1 from '../../assets/images/about-shape-1.svg'
import aboutShape2 from '../../assets/images/about-shape-2.svg'
import aboutShape3 from '../../assets/images/about-shape-3.png'
import aboutShape4 from '../../assets/images/about-shape-4.svg'
import { IonIcon } from '@ionic/react'
import { checkmarkDoneOutline } from 'ionicons/icons'
function About() {
    return (
        <div className='about section' id='about'>
            <div className="container">
                <figure className="about-banner">

                    <div className="img-holder" style= {{'--width': 520, '--height': 370 }}>
                        <img src= {aboutBanner} width="520" height="370" loading="lazy" alt="about banner"
                            className="img-cover" />
                    </div>

                    {/* <img src= {aboutShape1} width="360" height="420" loading="lazy" alt=""
                        className="shape about-shape-1" /> */}

                    <img src= {aboutShape2} width="371" height="220" loading="lazy" alt=""
                        className="shape about-shape-2" />

                    <img src= {aboutShape3} width="722" height="528" loading="lazy" alt=""
                        className="shape about-shape-3" />

                </figure>

                <div className="about-content">

                    <p className="section-subtitle">About Us</p>

                    <h2 className="h2 section-title">
                        Over 10 Years in <span className="span">Distant learning</span> for Skill Development
                    </h2>

                    <p className="section-text">
                        Lorem ipsum dolor sit amet consectur adipiscing elit sed eiusmod ex tempor incididunt labore dolore magna
                        aliquaenim ad
                        minim.
                    </p>

                    <ul className="about-list">

                        <li className="about-item">
                            <IonIcon icon= {checkmarkDoneOutline} aria-hidden="true" />

                            <span className="span">Expert Trainers</span>
                        </li>

                        <li className="about-item">
                            <IonIcon icon= {checkmarkDoneOutline} aria-hidden="true" />


                            <span className="span">Online Remote Learning</span>
                        </li>

                        <li className="about-item">
                            <IonIcon icon= {checkmarkDoneOutline} aria-hidden="true" />

                            <span className="span">Lifetime Access</span>
                        </li>

                    </ul>

                    <img src= {aboutShape4} width="100" height="100" loading="lazy" alt=""
                        className="shape about-shape-4" />

                </div>
            </div>
        </div>
    )
}

export default About