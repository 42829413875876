import React from 'react';
import './ContactUs.css'
import Contactbg from './image/contact-png.png'

const ContactUs = () => {
  return (
    <section className="contact-section" id='contact'>
      <div className="contact-bg">
        <h3>Get in Touch with Us</h3>
        <h2>Contact Us</h2>
        <div className="line">
          <div></div>
          <div></div>
          <div></div>
        </div>
        <p className="text">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Assumenda iste facilis quos impedit fuga nobis modi debitis laboriosam velit reiciendis quisquam alias corporis, maxime enim, optio ab dolorum sequi qui.</p>
      </div>

      <div className="contact-body">
        <div className="contact-info">
          <div>
            <span><i className="fas fa-mobile-alt"></i></span>
            <span>Phone No.</span>
            <span className="text">1-2392-23928-2</span>
          </div>
          <div>
            <span><i className="fas fa-envelope-open"></i></span>
            <span>E-mail</span>
            <span className="text">mail@company.com</span>
          </div>
          <div>
            <span><i className="fas fa-map-marker-alt"></i></span>
            <span>Address</span>
            <span className="text">1234 Dummy Street, Dummy State, India</span>
          </div>
          <div>
            <span><i className="fas fa-clock"></i></span>
            <span>Opening Hours</span>
            <span className="text">Monday - Friday (9:00 AM to 5:00 PM)</span>
          </div>
        </div>

        <div className="contact-form">
          <form>
            <div>
              <input type="text" className="form-control" placeholder="First Name" />
              <input type="text" className="form-control" placeholder="Last Name" />
            </div>
            <div>
              <input type="email" className="form-control" placeholder="E-mail" />
              <input type="text" className="form-control" placeholder="Phone" />
            </div>
            <textarea rows="5" placeholder="Message" className="form-control"></textarea>
            <input type="submit" className="send-btn" value="Send Message" />
          </form>

          <div>
            <img src={Contactbg} alt="" />
          </div>
        </div>
        
      </div>
      

      <div className="map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d241317.14571418948!2d72.71637348622276!3d19.08217751281333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c6306644edc1%3A0x5da4ed8f8d648c69!2sMumbai%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1715501266185!5m2!1sen!2sin"  width="100%" height="450"  style={{ border: 0 }} allowFullScreen="" aria-hidden="false" tabIndex="0" />
      </div>

      <div className="contact-footer">
        <h3>Follow Us</h3>
        <div className="social-links">
          <a href="#" className="fab fa-facebook-f"></a>
          <a href="#" className="fab fa-twitter"></a>
          <a href="#" className="fab fa-instagram"></a>
          <a href="#" className="fab fa-linkedin"></a>
          <a href="#" className="fab fa-youtube"></a>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
